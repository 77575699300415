<template>
    <v-modal
        :adaptive="true"
        :width="460"
        @before-open="modalOpened"
        height="auto"
        name="set-eta-modal"
    >
        <modal-content name="set-eta-modal">
            <div slot="header">
                <h2 class="font-normal">Set ETA</h2>
            </div>
            <div slot="body">
                <div class="flex justify-between">
                    <div>
                        Event will be originated at
                        <span
                            v-if="event.type && ! event.editOriginatedAt"
                        >{{ event.originated_at.format('Y-M-D HH:mm') }}</span>
                    </div>
                    <span
                        @click="event.editOriginatedAt = ! event.editOriginatedAt"
                        class="inline-block ml-2 text-blue-900 cursor-pointer"
                        v-text="event.editOriginatedAt ? 'Cancel' : 'Edit'"
                    >Edit</span>
                </div>
                <span v-if="event.editOriginatedAt">
                    <modal-field class="mt-4 mb-4" label="Originated date" required>
                        <date-picker :disable-past="false" v-model="event.originated_at"></date-picker>
                    </modal-field>
                    <modal-field class label="Originated time" required>
                        <div class="flex">
                            <select id="hour" name="hour" v-model="event.originated_at_hour">
                                <option
                                    :key="'h' + hour"
                                    :value="hour"
                                    v-for="hour in hours"
                                >{{ hour }}</option>
                            </select>
                            <select id="minute" name="minute" v-model="event.originated_at_minute">
                                <option
                                    :key="'m' + minute"
                                    :value="minute"
                                    v-for="minute in minutes"
                                >{{ minute }}</option>
                            </select>
                        </div>
                    </modal-field>
                </span>
                <div class="mt-12 mb-8">
                    <p class="text-sm text-smoke-400">Estimated Delivery Date</p>
                    <p
                        v-text="consignment.transportdates_etadate ? consignment.transportdates_etadate : 'No ETA'"
                    ></p>
                </div>
                <modal-field class="mt-4 mb-4" label="Date" required>
                    <date-picker :disable-past="false" v-model="event.time"></date-picker>
                </modal-field>
                <modal-field class label="Time" required>
                    <div class="flex">
                        <select id="hour" name="hour" v-model="event.time_hour">
                            <option :key="'h' + hour" :value="hour" v-for="hour in hours">{{ hour }}</option>
                        </select>
                        <select id="minute" name="minute" v-model="event.time_minute">
                            <option
                                :key="'m' + minute"
                                :value="minute"
                                v-for="minute in minutes"
                            >{{ minute }}</option>
                        </select>
                    </div>
                </modal-field>
            </div>
            <div slot="footer">
                <div class="flex justify-end w-full">
                    <div>
                        <button
                            @click="$modal.hide('set-eta-modal')"
                            class="px-8 mr-4 btn-grey-outline"
                        >Cancel</button>
                        <button
                            :disabled="loading"
                            @click="createEvent"
                            class="px-10 btn-primary"
                        >Save</button>
                    </div>
                </div>
                <!-- <pre class="overflow-auto text-xs max-h-80">{{ consignment }}</pre> -->
            </div>
        </modal-content>
    </v-modal>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import DatePicker from '~/components/DatePicker'

function newEvent() {
    return {
        type: 'TRANSPORT_EVENT',
        status_options: ['CONFIRMED', 'PICKUP', 'PRE_TRANSPORT', 'IN_TRANSIT', 'DELIVERED', 'ETA', 'CANCELLED', 'EXCEPTION'],
        selected_status: '',
        name: '', //Description,
        originated_at: moment(),
        originated_at_hour: moment().format('HH'),
        originated_at_minute: moment().format('mm'),
        editETA: false,
        time: moment(),
        time_hour: moment().format('HH'),
        time_minute: moment().format('mm'),
        editOriginatedAt: false
    }
}

export default {
    name: 'SetEtaModal',
    props: {
        shipment: {
            type: Object,
            required: true,
        }
    },
    components: { DatePicker },
    data() {
        return {
            event: {},
            consignment: {},
            loading: false
        }
    },
    methods: {
        modalOpened() {
            this.setEvent();
            this.fetchExtraData();
        },
        setEvent() {
            let event = Object.assign({}, newEvent());
            event.time_minute = this.currentQuarter;
            event.time_hour = this.currentHour;
            event.originated_at_minute = this.currentQuarter;
            this.event = event
        },
        async fetchExtraData() {
            try {
                const { data } = await axios.get(
                    this.$apiUrl.consignments.base + `/${this.shipment.uuid}`
                );
                this.consignment = data.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async createEvent() {
            this.loading = true;
            let event = { ...this.event };
            if (event.editOriginatedAt) {
                let originated_at = moment(event.originated_at)
                    .set({ hour: event.originated_at_hour, minute: event.originated_at_minute, second: 0 })
                    .format('YYYY-MM-DD HH:mm:ss')
                event.originated_at = originated_at;
            } else {
                event.originated_at = moment().format('YYYY-MM-DD HH:mm:ss');
            }

            let time = moment(event.time)
                .set({ hour: event.time_hour, minute: event.time_minute, second: 0 })
                .format('YYYY-MM-DD HH:mm:ss')

            event.time = time;
            event.status = 'ETA';
            event.name = event.status;

            try {
                let payload = {
                    consignment_uuid: this.consignment.uuid,
                    object: event
                }
                await axios.post(`${this.$apiUrl.consignments.base}/${this.consignment.uuid}/events`, payload.object)
                this.$snotify.success(`The ETA event was created successfully`);
                this.consignment.transportdates_etadate = time;
                setTimeout(() => {
                    this.loading = false;
                    this.$emit('created');
                    this.$modal.hide('set-eta-modal')
                }, 1200);
            } catch (error) {
                this.$snotify.warning(`Some fields failed to validate`)
                this.loading = false;
            }


            this.$emit('create', event)
        }
    },
    computed: {
        hours() {
            let hours = [];
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            for (let index = 0; index < 24; index++) {
                let hour = index;
                hours.push(zeroPad(hour, 2));
            }
            return hours;
        },
        currentQuarter() {
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            let now = moment().format('mm');
            now = parseInt(now);
            if (now > 0 && now < 15) {
                return 15;
            }
            if (now >= 15 && now < 30) {
                return 30;
            }
            if (now >= 30 && now < 45) {
                return 45;
            }
            return zeroPad(0, 2);
        },
        currentHour() {
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            let now = moment().format('mm');
            let nowHour = moment().format('H');
            if (now > 50) {
                nowHour = parseInt(nowHour) + 1;
            }
            return zeroPad(nowHour, 2)
        },
        minutes() {
            let minutes = ['00', '15', '30', '45'];
            return minutes;
        }
    }
}
</script>
